<template>
    <div>
        <component :is="'Page'"></component>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Page from './layout/index'

export default {
    components: {Page},
    created() {
      console.log('introducer created inspect token')
      if (!this.$store.getters.user) this.$store.dispatch('inspectToken', true);
    },
    computed: {
        ...mapGetters(['getThemeMode'])
    }
};
</script>
