<template>
  <div class="side-content-wrap"
       @mouseenter="isMenuOver = true"
       @mouseleave="isMenuOver = false"
       @touchstart="isMenuOver = true">

    <vue-perfect-scrollbar ref="myData"
                           :class="{ open: getSideBarToggleProperties.isSideNavOpen, 'remove-top': !showTop }"
                           :settings="{ suppressScrollX: true, wheelPropagation: false }"
                           class="sidebar-left rtl-ps-none ps scroll">
      <div>
        <ul class="navigation-left">
          <li :class="{ active: selectedParentMenu === 'dashboard' }"
              class="nav-item"
              data-item="dashboard"
              @mouseenter="toggleSubMenu">
            <router-link class="nav-item-hold"
                         href="#"
                         to="/introducer/dashboard">
              <i class="nav-icon i-Home1"></i>
              <span class="nav-text">Home</span>
            </router-link>
            <div class="triangle"></div>
          </li>


          <li v-if="!production"
              :class="{ active: selectedParentMenu === 'coupons' }"
              class="nav-item"
              data-item="coupons"
              @mouseenter="toggleSubMenu">
            <router-link :to="{name: 'Coupons'}"
                         class="nav-item-hold"
                         href="#"
                         >
              <i class="nav-icon i-Ticket"></i>
              <span class="nav-text">Coupons</span>
            </router-link>
            <div class="triangle"></div>
          </li>

          <li :class="{ active: selectedParentMenu === 'transactions' }"
              class="nav-item"
              data-item="transactions"
              @mouseenter="toggleSubMenu">
            <router-link :to="{name: 'Transactions'}"
                         class="nav-item-hold"
                         href="#"
                         >
              <i class="nav-icon i-Repeat2"></i>
              <span class="nav-text">Referrals</span>
            </router-link>
            <div class="triangle"></div>
          </li>


          <li :class="{ active: selectedParentMenu === 'reports' }"
              class="nav-item"
              data-item="reports"
              @mouseenter="toggleSubMenu">
            <router-link :to="{name: 'ReportCommissions'}"
                         class="nav-item-hold"
                         href="#"
                         >
              <i class="nav-icon i-File-Graph"></i>
              <span class="nav-text">Reports</span>
            </router-link>
            <div class="triangle"></div>
          </li>

          <li v-if="!production"
              :class="{ active: selectedParentMenu === 'learning' }"
              class="nav-item"
              data-item="learning"
              @mouseenter="toggleSubMenu">
            <router-link :to="{name: 'Learning'}"
                         class="nav-item-hold"
                         href="#"
                         >
              <i class="nav-icon i-University"></i>
              <span class="nav-text">Learning</span>
            </router-link>
            <div class="triangle"></div>
          </li>

          <li :class="{ active: selectedParentMenu === 'stats' }"
              class="nav-item"
              data-item="stats"
              @mouseenter="toggleSubMenu">
            <router-link :to="{name: 'Statistics'}"
                         class="nav-item-hold"
                         href="#"
                         >
              <i class="nav-icon i-Statistic"></i>
              <span class="nav-text">Statistics</span>
            </router-link>
            <div class="triangle"></div>
          </li>

          <li v-if="!showTop"
              :class="{ active: selectedParentMenu === 'back' }"
              class="nav-item"
              data-item="back">
            <a class="nav-item-hold"
               href="#"
               @click="backTo"> <i class="nav-icon i-Back1"></i>
              <span class="nav-text">Back</span>
            </a>
          </li>

        </ul>
      </div>
    </vue-perfect-scrollbar>

    <vue-perfect-scrollbar :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
                           :settings="{ suppressScrollX: true, wheelPropagation: false }"
                           class="sidebar-left-secondary ps rtl-ps-none">
      <div ref="sidebarChild">
        <!-- Submenu Dashboards -->
        <ul :class="{ 'd-block': selectedParentMenu === 'dashboard' }"
            class="childNav d-none"
            data-parent="dashboard">
          <li class="nav-item">
            <router-link

                         to="/introducer/dashboard">
              <i class="nav-icon i-Statistic"></i>
              <span class="item-name">Dashboard</span>
            </router-link>
          </li>

        </ul>
        <ul :class="{ 'd-block': selectedParentMenu === 'transactions' }"
            class="childNav d-none"
            data-parent="transactions">
          <li class="nav-item">
            <router-link :to="{name: 'Transactions'}"
                         >
              <i class="nav-icon i-Administrator"></i>
              <span class="item-name">All Transactions</span>
            </router-link>
          </li>
          <li v-if="$store.getters.user && $store.getters.user.is_introducer_admin" class="nav-item">
            <router-link :to="{name: 'TransactionsManage'}"
                         >
              <i class="nav-icon i-Administrator"></i>
              <span class="item-name">Manage Transactions</span>
            </router-link>
          </li>
        </ul>

        <ul :class="{ 'd-block': selectedParentMenu === 'reports' }"
            class="childNav d-none"
            data-parent="reports">
          <li class="nav-item">
            <router-link :to="{name: 'ReportCommissions'}"
                         >
              <i class="nav-icon i-Administrator"></i>
              <span class="item-name">Commissions</span>
            </router-link>
          </li>
        </ul>


        <ul :class="{ 'd-block': selectedParentMenu === 'learning' }"
            class="childNav d-none"
            data-parent="learning">
          <li class="nav-item">
            <router-link :to="{name: 'Learning'}"
                         >
              <i class="nav-icon i-University"></i>
              <span class="item-name">Courses</span>
            </router-link>
          </li>
        </ul>


      </div>
    </vue-perfect-scrollbar>
    <div :class="{ open: getSideBarToggleProperties.isSecondarySideNavOpen }"
         class="sidebar-overlay"
         @mouseenter="removeOverlay()"></div>
  </div>
</template>

<script>
import {isMobile} from 'mobile-device-detect';
import {mapActions, mapGetters} from 'vuex';

export default {
  props: {
    showTop: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isDisplay: true,
      isMenuOver: false,
      isStyle: true,
      selectedParentMenu: '',
      isMobile,
      configList: []
    };
  },
  mounted() {
    this.toggleSelectedParentMenu();
    window.addEventListener('resize',
        this.handleWindowResize);
    document.addEventListener('click',
        this.returnSelectedParentMenu);
    this.handleWindowResize();
  },

  beforeDestroy() {
    document.removeEventListener('click',
        this.returnSelectedParentMenu);
    window.removeEventListener('resize',
        this.handleWindowResize);
  },
  watch: {
    $route() {
      this.returnSelectedParentMenu();
    }
  },
  computed: {
    ...mapGetters(['getSideBarToggleProperties']),
    production() {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',
      'changeSecondarySidebarPropertiesViaMenuItem',
      'changeSecondarySidebarPropertiesViaOverlay',
      'changeSidebarProperties'
    ]),
    getLinks(section,
             filterDefault) {
      if (this.configList) {
        return this.configList.filter((c) => c.section === section && c.isdefault === filterDefault).map((x) => ({
          value: x.id,
          text: x.name
        }));
      }
      return [];
    },
    handleWindowResize() {
      //  console.log('not working is Mobile');
      if (window.innerWidth <= 1200) {
        if (this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
        if (this.getSideBarToggleProperties.isSecondarySideNavOpen) {
          this.changeSecondarySidebarProperties();
        }
      } else {
        if (!this.getSideBarToggleProperties.isSideNavOpen) {
          this.changeSidebarProperties();
        }
      }
    },
    toggleSelectedParentMenu() {
      const currentParentUrl = this.$route.path.split('/').filter((x) => x !== '' && x !== 'introducer')[0];

      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase();
      } else {
        this.selectedParentMenu = 'dashboards';
      }
    },
    toggleSubMenu(e) {
      this.selectedParentMenu = e.target.dataset.item;
      setTimeout(() => {
            this.changeSecondarySidebarPropertiesViaMenuItem(true);
          },
          1000);
    },
    highlightOnly(e) {
      this.selectedParentMenu = e.target.dataset.item;
      this.changeSecondarySidebarPropertiesViaMenuItem(false);
    },
    removeOverlay() {
      this.changeSecondarySidebarPropertiesViaOverlay();
      if (window.innerWidth <= 1200) {
        this.changeSidebarProperties();
      }
      this.toggleSelectedParentMenu();
    },
    returnSelectedParentMenu() {
      if (!this.isMenuOver) {
        this.toggleSelectedParentMenu();
      }
    },

    toggleSidebarDropdwon(event) {
      let dropdownMenus = this.$el.querySelectorAll('.dropdown-sidemenu.open');

      event.currentTarget.classList.toggle('open');

      dropdownMenus.forEach((dropdown) => {
        dropdown.classList.remove('open');
      });
    },
    backTo() {
      this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
.remove-top {
  top: 0 !important;
  height: 100%;
}
</style>
