<template>
  <div class="main-header">
    <div class="logo">
      <img alt :src="logo"/>
    </div>

    <div class="menu-toggle" @click="sideBarToggle">
      <div></div>
      <div></div>
      <div></div>
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">

      <!-- User avatar dropdown -->
      <div class="dropdown">
        <b-dropdown
            id="dropdown-1"
            class="m-md-2 user col align-self-end"
            no-caret
            text="Dropdown Button"
            toggle-class="text-decoration-none"
            variant="link"
        >
          <template slot="button-content">
            <img
                id="userDropdown"
                :src="image_thumb"
                alt
                aria-expanded="false"
                aria-haspopup="true"
                data-toggle="dropdown"
            />
          </template>

          <div
              aria-labelledby="userDropdown"
              class="dropdown-menu-right pt-2 pb-2"
          >
            <template v-if="user">
              <div class="dropdown-header">
                <i class="i-Business-Man mr-1"></i> {{ user.name }}
              </div>
              <div class="dropdown-header">
                <i class="i-Business-Mens mr-1"></i> {{ introducer }}
              </div>
              <div class="dropdown-header">
                <i class="i-Email mr-1"></i> {{ user.email }}
              </div>
            </template>

            <div v-else class="dropdown-header">
              <i class="i-Lock-User mr-1"></i> ANONYMOUS USER
            </div>
            <hr class="mb-2 mt-2">
            <router-link class="dropdown-header link" :to="{name: 'IntroducerSettings'}">
              <i  class="i-Security-Check mr-1"></i>Settings
            </router-link>

            <div class="dropdown-header link" @click.prevent="logoutUser">
              <i  class="i-Remove-User mr-1"></i>Sign Out
            </div>
          </div>
        </b-dropdown>
      </div>
    </div>

  </div>

  <!-- header top menu end -->
</template>

<script>
import {isMobile} from 'mobile-device-detect';
import {mapActions, mapGetters} from 'vuex';
import {mixin as clickaway} from 'vue-clickaway';

// import SearchResults from '../common/search/index';

export default {
  mixins: [clickaway],
  components: {
    // SearchResults
  },
  data() {
    return {
      searchQuery: null,
      calendarDate: new Date(),
      isDisplay: true,
      isStyle: true,
      isSearchOpen: false,
      isMouseOnMegaMenu: true,
      isMegaMenuOpen: false
    };
  },
  computed: {
    ...mapGetters(['getSideBarToggleProperties']),
    user() {
      return this.$store.getters.user
    },
    introducer() {
      if (this.user && this.user.is_introducer) {
        return this.user.is_introducer.introducer
      }
      return 'NO INTRODUCER'
    },
    logo(){
      let img = require('@/assets/images/logo2.png');
      if (
          this.$store.getters.user &&
          this.$store.getters.user.is_introducer &&
          this.$store.getters.user.is_introducer.logo
      ) {
        return this.$store.getters.user.is_introducer.logo;
      }
      return img;
    },
    image_thumb() {
      let thumb = require('@/assets/images/man-profile.svg');
      if (
          this.$store.getters.user &&
          this.$store.getters.user.image_thumb
      ) {
        return this.$store.getters.user.image_thumb;
      } else if (
          this.$store.getters.user &&
          this.$store.getters.user.image
      ) {
        return this.$store.getters.user.image;
      }
      return thumb;
    }
  },
  methods: {
    ...mapActions([
      'changeSecondarySidebarProperties',

      'changeSidebarProperties',
      'changeThemeMode',
      'signOut'
    ]),
    logoutUser() {
      localStorage.clear();
      this.$store.dispatch('logoutIntroducer')
    },
    toggleSearch() {
      this.isSearchOpen = !this.isSearchOpen;
    },

    sideBarToggle() {
      if (
          this.getSideBarToggleProperties.isSideNavOpen &&
          this.getSideBarToggleProperties.isSecondarySideNavOpen &&
          isMobile
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      } else if (
          this.getSideBarToggleProperties.isSideNavOpen &&
          this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSecondarySidebarProperties();
      } else if (this.getSideBarToggleProperties.isSideNavOpen) {
        this.changeSidebarProperties();
      } else if (
          !this.getSideBarToggleProperties.isSideNavOpen &&
          !this.getSideBarToggleProperties.isSecondarySideNavOpen &&
          !this.getSideBarToggleProperties.isActiveSecondarySideNav
      ) {
        this.changeSidebarProperties();
      } else if (
          !this.getSideBarToggleProperties.isSideNavOpen &&
          !this.getSideBarToggleProperties.isSecondarySideNavOpen
      ) {
        this.changeSidebarProperties();
        this.changeSecondarySidebarProperties();
      }
    }
  }
};
</script>
